"use client";
import { Fragment, useContext, useState } from "react";
import { signOut, useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Menu, Transition } from "@headlessui/react";

import { classNames, getProfileImage } from "@lib/helpers";
import { getRole, isAdmin } from "@admin/_utils/helpers";

import { Plan, Role, Subscription } from "@prisma/client";
import { useIntl } from "react-intl";
import { useCookies } from "next-client-cookies";
import { EmployerProfileImageContext } from "@context/ProfileContext";
import LogoutModal from "./LogoutModal";

export default function Avatar({
  profileImage,
  role,
  name,
  email,
  userId,
  subscription,
  plan,
}: // slug,
{
  profileImage: string | null;
  role: Role;
  name: string | null;
  email: string | null;
  userId: string | null;
  subscription: Subscription | null;
  plan: Plan | null;
  // slug: string | null;
}) {
  const pathname = usePathname();
  const intl = useIntl();
  const session = useSession();
  const cookies = useCookies();
  const isArabic = cookies.get("lang") === "ar";

  const subscriptionTranslation: any = intl.messages.subscription;
  const headerTranslation: any = intl.messages.header;
  const rolesTranslation: any = intl.messages.roles;

  const profilePopupTranslation: any = headerTranslation["profile-popup"];

  const getProfileLinks = () => {
    if (role === "CANDIDATE") {
      return [
        {
          name: profilePopupTranslation["dashboard-text"],
          href: "/candidate/profile",
        },
        {
          name: profilePopupTranslation["edit-profile-text"],
          href: "/candidate/profile/edit",
        },
        {
          name: profilePopupTranslation["public-view-text"],
          href: `/candidate/${userId}`,
        },
        { name: profilePopupTranslation?.["messages-text"], href: `/chat` },
        {
          name: profilePopupTranslation["manage-cv-text"],
          href: `/candidate/profile/cv`,
        },
      ];
    }
    if (role === "EMPLOYER") {
      return [
        {
          name: profilePopupTranslation["dashboard-text"],
          href: "/employer/profile",
        },
        {
          name: profilePopupTranslation["edit-profile-text"],
          href: "/employer/profile/edit",
        },
        // { name: "Public View", href: `/companies/${userId}` },
        { name: profilePopupTranslation?.["messages-text"], href: `/chat` },
        {
          name: profilePopupTranslation["applications-text"],
          href: `/employer/profile/applications`,
        },
        {
          name: headerTranslation["post-job"],
          href: `/employer/profile/jobs/post`,
        },
      ];
    }
    if (isAdmin(role)) {
      return [
        { name: "Dashboard", href: "/admin" },
        // { name: "Admin Users", href: "/admin/users" },
      ];
    }
  };

  const [profileImg, setProfileImg] = useState<string>(profileImage || "");
  const imageContext = useContext(EmployerProfileImageContext);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  imageContext.setProfileImage = setProfileImg;

  return (
    <Menu
      as="div"
      className={classNames(
        "relative inline-block",
        isArabic ? "text-right" : "text-left"
      )}
    >
      <LogoutModal open={logoutModalOpen} setOpen={setLogoutModalOpen} primaryClick={() => signOut({ callbackUrl: '/', redirect:true })} />
      <div className="flex items-center">
        <Menu.Button className="inline-flex flex-row-reverse md:flex-row items-center gap-3 shrink-0">
          <div
            className={classNames(
              "",
              isArabic ? "text-right md:text-left" : "text-left md:text-right"
            )}
          >
            <p className="text-lg md:text-sm font-semibold text-white">
              {name?.includes("null") ? "" : name?.split?.(" ")?.[0]}
            </p>
            {!session.data?.user.EmployerId ? (
              <p className="text-xs text-white">
                {getRole(role, rolesTranslation)}
              </p>
            ) : (
              ""
            )}
          </div>
          <Image
            src={getProfileImage({
              image: profileImg || "",
              name: name || "",
              id: userId || "",
            })}
            alt={name || "Profile Image"}
            className="w-14 h-14 lg:w-12 lg:h-12 rounded-full object-contain object-center "
            width={56}
            height={56}
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            isArabic
              ? "bottom-16 right-0 md:bottom-[initial] md:right-[initial] md:left-0 origin-bottom-right md:origin-top-left"
              : "bottom-16 left-0 md:bottom-[initial] md:left-[initial] md:right-0 origin-bottom-left md:origin-top-right",
            "absolute z-10 mt-3 w-80 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-gray-700 ring-opacity-5 focus:outline-none"
          )}
        >
          <div className="px-4 py-3 flex items-center gap-3">
            <Image
              src={getProfileImage({
                image: profileImage || "",
                name: name || "",
                id: userId || "",
              })}
              alt={name || "Profile Image"}
              className="w-16 h-16 rounded-full object-contain object-center"
              width={64}
              height={64}
            />
            <div className={classNames("max-w-[calc(100%-70px)] w-full")}>
              <p className="text-lg font-semibold text-gray-900 mb-px">
                {name?.includes("null") ? "" : name}
              </p>
              <p className="truncate text-sm text-gray-500">{email}</p>
            </div>
          </div>
          {subscription?.id ? (
            <div className="py-2.5 px-4">
              <p className="text-gray-400 text-sm font-semibold mb-1">
                {plan?.name} {headerTranslation?.["plan"]}{" "}
                {subscription.status === "EXPIRED"
                  ? `(${subscriptionTranslation?.["expired"]})`
                  : null}
              </p>
              <div className="text-gray-400 text-xs flex justify-between items-center font-semibold">
                <span>
                  {headerTranslation?.["invites"]}: {subscription?.invites}
                </span>
                <span>
                  {headerTranslation?.["jobs_quota"]}: {subscription?.jobs}
                </span>
                <span>
                  {headerTranslation?.["unlocks"]}: {subscription?.unlocks}
                </span>
              </div>
            </div>
          ) : null}

          <div className="py-1">
            {getProfileLinks()?.map((profile, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <Link
                    href={profile.href}
                    className={classNames(
                      active || profile.href === pathname
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-700",
                      "block px-4 py-2 text-[15px]"
                    )}
                  >
                    {profile.name}
                  </Link>
                )}
              </Menu.Item>
            ))}
            {/* <LocalizationClientComponent /> */}
          </div>
          <div className="py-1">
            <div>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setLogoutModalOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      isArabic ? "text-right" : "text-left",
                      "block w-full px-4 py-2 text-[15px]"
                    )}
                  >
                    {headerTranslation?.["logout"]}
                  </button>
                )}
              </Menu.Item>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
