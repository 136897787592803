"use client";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../ui/button";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import TextInput from "@components/Forms/TextInput";
import SelectInput from "@components/Forms/SelectInput";
import Textarea from "@components/Forms/Textarea";
import { createFeedback } from "./_server/actions";
import { convertObjectToArrayOptions } from "@lib/helpers";
import { FEEDBACK_TYPES } from "@lib/constants";

const Feedback = () => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Give Feedback</Button>
      </DialogTrigger>
      <DialogContent className="max-w-xl">
        <DialogTitle className="text-xl">Help us improve</DialogTitle>
        <DialogDescription>
          Share your thoughts and suggestions by providing feedback.
        </DialogDescription>
        <FeedbackForm closeModal={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  );
};

export default Feedback;

interface FeedbackFormProps {
  closeModal: () => void;
}

const FeedbackForm = ({ closeModal }: FeedbackFormProps) => {
  //   const [rating, setRating] = useState(0);

  //   const handleRatingChange = (newRating: number) => {
  //     setRating(newRating);
  //   };

  const defaultValues = {
    name: "",
    email: "",
    type: "general",
    details: "",
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data: typeof defaultValues) => {
    const res = await createFeedback({ data });
    if (!res)
      return toast.error("Something went wrong. Please contact support.");

    toast.success("Feedback submitted! Thanks for helping us improve.");
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="space-y-2">
        <Controller
          control={control}
          rules={{ required: "Name is required" }}
          name="name"
          render={({ field: { name, ref, value, onChange } }) => (
            <TextInput
              label="Your Name*"
              className="w-full"
              inputSize="small"
              id="feedback-name"
              value={value}
              inputRef={ref}
              onChange={onChange}
              error={errors?.[name]?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          rules={{ required: "Email is required" }}
          render={({ field: { name, ref, value, onChange } }) => (
            <TextInput
              label="Your Email*"
              className="w-full"
              type="email"
              inputSize="small"
              id="feedback-email"
              inputRef={ref}
              value={value}
              onChange={onChange}
              error={errors?.[name]?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="type"
          rules={{ required: "Feedback Type is required" }}
          render={({ field: { name, ref, value, onChange } }) => (
            <SelectInput
              selectRef={ref}
              value={value}
              onChange={onChange}
              label="Feedback Type*"
              id="feedback-type"
              inputSize="small"
              className="w-full"
              error={errors?.[name]?.message}
              options={convertObjectToArrayOptions(FEEDBACK_TYPES)}
            />
          )}
        />

        <Controller
          control={control}
          name="details"
          rules={{ required: "Feedback Details is required" }}
          render={({ field: { name, ref, value, onChange } }) => (
            <Textarea
              textareaRef={ref}
              value={value}
              onChange={onChange}
              label="Feedback Details*"
              id="feedback-details"
              rows={4}
              error={errors?.[name]?.message}
            />
          )}
        />
        {/* <div className="flex gap-0.5">
          {[...Array(5)].map((_, index) => (
            <button
              type="button"
              className="text-dark-4"
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => handleRatingChange(index + 1)}
            >
              {index < rating ? (
                <StarFilledIcon width={26} height={26} />
              ) : (
                <StarIcon width={26} height={26} />
              )}
            </button>
          ))}
        </div> */}

        <DialogFooter className="pt-3">
          <Button type="submit" disabled={isSubmitting}>
            Submit feedback
          </Button>
        </DialogFooter>

        {/* <TextInput inputSize="small" className="w-full"></TextInput> */}
      </div>
    </form>
  );
};
