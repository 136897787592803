"use client";
import React, { useEffect, useMemo, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { FiArrowRightCircle, FiMenu } from "react-icons/fi";
import { usePathname } from "next/navigation";
import { signOut, useSession } from "next-auth/react";
import { useIntl } from "react-intl";
import { Session } from "next-auth";
import Link from "next/link";
import Image from "next/image";
import { MdLogout } from "react-icons/md";
import { useCookies } from "next-client-cookies";

import { cn } from "@lib/utils";
import { classNames } from "@lib/helpers";

// Assets
import Logo from "@assets/images/logo.svg";

// Icons
import { IoMdClose, IoMdMenu } from "react-icons/io";

// Components
import { Button } from "../../button";
import NavLink from "./NavLink";
import Avatar from "./Avatar";
import LanguageDropdown from "./LanguageDropdown";
import { endUserSession, getUserActiveStatus } from "@queries/session";
import ChatNotification from "./ChatNotification";
import { Plan, Subscription } from "@prisma/client";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import useIsMobile from "@hooks/useIsMobile";
import { useUserOnboardingStore } from '@store/onboarding';

const HeaderClient = ({
  initialSession,
  subscription,
  plan,
}: {
  initialSession: Session | null;
  subscription: Subscription | null;
  plan: Plan | null;
}) => {
  const { data, status } = useSession();
  const pathname = usePathname();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const { user } = useUserOnboardingStore();

  const cookies = useCookies();

  const isArabic = cookies.get("lang") === "ar";

  const [session, setSession] = useState<Session | null>(initialSession);

  const [isHeaderTransparent, setIsHeaderTransparent] = useState(
    pathname === "/" ? true : false
  );

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      if (session) {
        const isActive = await getUserActiveStatus(session);
        if (!isActive) {
          await endUserSession(session);
        }
      }
    };

    getUserInfo();
    if (status !== "loading") {
      setSession(data);
    }
  }, [session, status, data]);

  useEffect(() => {
    const handleScroll = () => {
      const featuredCategoriesSection = document.getElementById(
        "featured-categories"
      );
      if (window.scrollY + 90 > (featuredCategoriesSection?.offsetTop || 0)) {
        setIsHeaderTransparent(false);
      } else {
        setIsHeaderTransparent(pathname === "/");
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  const headerTranslation: any = intl.messages.header;
  const aboutTranslation: any = intl.messages.about;
  const contactTranslation: any = intl.messages.contact;

  const EMPLOYER_NAV_ITEMS = useMemo(
    () => [
      {
        title: headerTranslation["home-text"],
        href: "/",
      },
      {
        title: headerTranslation["candidate-applications"],
        href: "/employer/profile/applications",
      },
      {
        title: headerTranslation["post-job"],
        href: "/employer/profile/jobs/post",
      },
      {
        title: headerTranslation["pricing-text"],
        href: "/pricing",
      },
    ],
    [headerTranslation]
  );

  const NAV_ITEMS = useMemo(
    () => [
      {
        title: headerTranslation["pricing-text"],
        href: "/pricing",
      },
      {
        title: headerTranslation["blog-text"],
        href: "/blog",
      },
      {
        title: aboutTranslation["breadcrumb"]["page"],
        href: "/about",
      },
      {
        title: contactTranslation["breadcrumb"]["title"],
        href: "/contact",
      },
    ],
    [headerTranslation]
  );

  const CANDIDATE_NAV = useMemo(
    () => [
      {
        title: headerTranslation["home-text"],
        href: "/",
      },
      {
        title: headerTranslation["explore-jobs"],
        href: "/jobs",
      },
      {
        title: headerTranslation["companies-text"],
        href: "/companies",
      }
    ],
    [headerTranslation]
  );

  const navItems = useMemo(() => {
    if (session?.user?.role === "EMPLOYER") {
      return isMobile ? [...EMPLOYER_NAV_ITEMS, ...NAV_ITEMS] : EMPLOYER_NAV_ITEMS;
    } else if (session?.user?.role === "CANDIDATE") {
      return isMobile ? [...CANDIDATE_NAV, ...NAV_ITEMS] : CANDIDATE_NAV;
    } else {
      return isMobile ? [...CANDIDATE_NAV, ...NAV_ITEMS] : [...CANDIDATE_NAV, ...(NAV_ITEMS.slice(0, 2))];
    }
  }, [CANDIDATE_NAV, EMPLOYER_NAV_ITEMS, NAV_ITEMS, session?.user?.role, isMobile]);

  useEffect(() => {
    const html = document?.querySelector("body");
    if (!html) return () => { };

    if (menuOpen) {
      html.style.overflow = "hidden";
    } else {
      html.style.removeProperty("overflow");
    }

    return () => { };
  }, [menuOpen]);

  const toggleMenu = () => {
    const html = document?.querySelector("html");
    if (!html) return () => { };

    if (menuOpen) {
      html.style.removeProperty("overflow");
      setMenuOpen(false);
    } else {
      html.style.overflow = "hidden";
      setMenuOpen(true);
    }

    return () => { };
  };

  useEffect(() => {
    const html = document?.querySelector("html");
    if (!html) return () => { };
    html.style.removeProperty("overflow");
    setMenuOpen(false);
  }, [pathname]);

  return (
    <>
      {pathname !== "/" ? (
        <div
          className={cn(
            subscription?.status === "EXPIRED"
              ? "h-[152px] sm:h-[142px]"
              : "h-20 sm:h-[90px]",
            "w-full"
          )}
        ></div>
      ) : null}
      <header
        className={cn(
          "sticky bg-dark-2 flex flex-col w-full fixed top-0 left-0 right-0 z-50 shadow",
          subscription?.status === "EXPIRED"
            ? "h-[152px] sm:h-[142px]"
            : "h-20 sm:h-[90px]",
          // pathname === "/" && !isHeaderTransparent ? "fixed" : "",
          pathname === "/" && isHeaderTransparent
            ? "bg-dark-2 sm:bg-transparent shadow-none sm:absolute"
            : "",
          pathname === "/" && !isHeaderTransparent ? "sm:fixed" : "",
          menuOpen ? "bg-dark-2 fixed shadow" : ""
        )}
      >
        {subscription?.status === "EXPIRED" ? (
          <div>
            <div className="ltr:border-l-4 rtl:border-r-4 border-yellow-400 bg-yellow-50 p-4">
              <div className="flex gap-3">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="">
                  <p className="text-sm text-yellow-700">
                    {headerTranslation?.["subscription-expired-text-1"]}{" "}
                    <Link
                      href="/pricing"
                      className="font-medium text-yellow-700 underline hover:text-yellow-600"
                    >
                      {headerTranslation?.["subscription-expired-text-link"]}
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="flex-1 flex items-center">
          <div className="lg-container">
            <div className="flex gap-5 justify-between lg:justify-start items-center xl:justify-between">
              <div className="flex gap-8 items-center">
                <Link href="/">
                  <Image
                    src={Logo}
                    width={176}
                    height={72}
                    alt="Wazifame Logo"
                    className="inline-block rounded w-36 md:w-[170px] object-contain object-left"
                    loading="lazy"
                  />
                </Link>

                {session?.user?.role !== "EMPLOYER" ? (
                <Button
                  asChild
                  variant="lightGray"
                  className="pr-5 hidden md:flex"
                >
                  <Link href="/jobs">
                    <FiMenu className="mr-3 text-xl" />
                    {headerTranslation["jobs-text"]}
                  </Link>
                </Button>
                ) : null} 
              </div>
              <nav className="hidden lg:block mr-auto pl-4 xl:pl-0 xl:mr-0 left-1/2">
                <ul className="flex gap-3 xl:gap-5 items-center">
                  {navItems.map((item, index) => {
                    if (!isMobile && item.href === "/jobs") {
                      return null;
                    }
                    return (
                      <li key={index}>
                        <NavLink href={item.href}>{item.title}</NavLink>
                      </li>
                    );
                  })}
                  <li className="hidden lg:inline-block">
                    <LanguageDropdown />
                  </li>
                </ul>
              </nav>
              <div className="flex items-center gap-3 sm:gap-5">
                <div className="block lg:hidden">
                  <LanguageDropdown />
                </div>
                {session?.user?.id ? (
                  <div className="hidden md:flex items-center gap-3">
                    <ChatNotification session={session} />
                    <Avatar
                      subscription={subscription}
                      plan={plan}
                      name={
                        user
                          ? `${user?.name ?? ""}`
                          : session.user.name || ""
                      }
                      email={session.user.email || ""}
                      role={session.user.role}
                      userId={session.user.id}
                      profileImage={session?.user?.image}
                    />
                  </div>
                ) : (
                  <div className="hidden gap-2.5 xl:gap-3.5 md:flex">
                    <Button asChild variant="default" className="xl:pr-5 gap-2">
                      <Link href="/login">
                        <FiArrowRightCircle
                          className={classNames(
                            "text-[17px]",
                            isArabic ? "rotate-180" : ""
                          )}
                        />
                        {headerTranslation["sign-in"]}
                      </Link>
                    </Button>
                    <Button asChild variant="secondary" className="gap-2">
                      <Link href="/register">
                        <FaRegEdit className={"text-[17px]"} />
                        {headerTranslation["register"]}
                      </Link>
                    </Button>
                  </div>
                )}
                <button
                  onClick={() => toggleMenu()}
                  className="lg:hidden bg-[#35404d] text-3xl p-2 border border-gray-500 text-white rounded-full"
                  aria-label={menuOpen ? "Close menu" : "Open menu"}
                  title={menuOpen ? "Close menu" : "Open menu"}
                >
                  {menuOpen ? <IoMdClose /> : <IoMdMenu />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {menuOpen ? (
        <div
          className={cn(
            "flex p-6 flex-col bg-dark-2 border-t border-dark-4 z-40 lg:hidden",
            subscription?.status === "EXPIRED"
              ? "h-[calc(100vh-152px)] sm:h-[calc(100vh-142px)] w-full fixed top-[152px] sm:top-[142px]"
              : "h-[calc(100vh-80px)] sm:h-[calc(100vh-90px)] w-full fixed top-20 sm:top-[90px]"
          )}
        >
          <nav className="flex-1 text-center flex items-center overflow-auto mb-4">
            <ul className="w-full h-full space-y-4 md:space-y-5">
              {navItems.map((item) => {
                if (item.href === "/") {
                  return null;
                }
                return (
                  <li key={item.title} className={cn(pathname === item.href ? "bg-primary" : "bg-white/10 border border-white/20", "p-4 rounded-2xl")}>
                    <NavLink href={item?.href} className="p-0 block">{item?.title}</NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>
          <footer className="shadow-md mb-4">
            {session?.user?.id ? (
              <div className="flex justify-between items-center">
                <Avatar
                  subscription={subscription}
                  plan={plan}
                  name={session.user.name || ""}
                  email={session.user.email || ""}
                  role={session.user.role}
                  userId={session.user.id}
                  profileImage={session?.user?.image}
                />
                <Button
                  onClick={() => signOut()}
                  className="gap-2"
                  variant="gray"
                >
                  {headerTranslation?.["logout"]}
                  <MdLogout className="text-base" />
                </Button>
              </div>
            ) : (
              <div className="grid sm:grid-cols-2 gap-3 sm:gap-4">
                <Button asChild className="!h-16 !rounded-2xl gap-4 !text-xl !font-semibold">
                  <Link href="/login">
                    <FiArrowRightCircle className="text-[20px]" />
                    {headerTranslation["sign-in"]}
                  </Link>
                </Button>
                <div className="text-white text-xl leading-[30px]">{headerTranslation["do-not-have-account"]}</div>
                <Link href="/register">
                  <div className="text-[#e1c3fe] text-xl underline leading-[30px]">{headerTranslation["register-now"]}</div>
                </Link>
              </div>
            )}
          </footer>
        </div>
      ) : null}
    </>
  );
};

export default HeaderClient;
